import { useEffect, useRef } from 'react';

export default function VideoPlayer({
  m3u8,
  mp4,
  poster,
}: {
  m3u8: string;
  mp4: string;
  poster?: string;
}): JSX.Element {
  const video = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    import('hls.js').then(({ default: Hls }) => {
      if (!video.current) {
        return;
      }

      if (Hls.isSupported()) {
        const hls = new Hls({ startLevel: 3 });
        hls.loadSource(m3u8);
        hls.attachMedia(video.current);
      } else {
        video.current.src = mp4;
      }
    });
  }, []);

  return (
    <video
      playsInline
      loop
      controls
      autoPlay
      className="w-full h-full object-contain"
      crossOrigin="anonymous"
      poster={poster}
      ref={video}
    />
  );
}
